import React from "react"

import SEO from "../components/seo"
import { Test } from "../components/test"

const IndexPage = () => {
  return (
    <>
      <SEO />
      <Test/>
    </>
  )
}

export default IndexPage
